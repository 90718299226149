.Location {

    display: flex;
    flex-direction: column;

    > summary {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        [data-site='materials-energy-expo'] & {
            font-size: var(--font-size-header);
        }
    }

    summary::marker { display: none; }

    > * { margin: 0 0 1em 0; }

}
