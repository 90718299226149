.Modal {
    text-shadow: none;
    padding: 0;
    margin-top: var(--spacing-medium);
    margin-bottom: var(--spacing-medium);
    height: auto;
    filter: drop-shadow(0 0 32px white);
    overscroll-behavior: contain;
    text-align: left;
    &::backdrop {
        background: hsla(0 0% 0% / 0.7);
        backdrop-filter: grayscale(1) blur(32px);
    }
    header:not(:empty) {
        position: sticky;
        z-index: var(--layer-menu);
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: var(--spacing-small-half);
        background: var(--colour-background-accent-primary);
        filter: var(--filter-shadow-mid);
        --colour-link: var(--colour-text-secondary);
        --colour-link-active: var(--colour-text-accent-primary);
        [data-site='events'] & {
            @media (max-width: 500px) {
                flex-direction: column;
                gap: var(--spacing-small);
            }
            > button {
                background: var(--colour-background-accent-secondary);
            }
            > h1 {
                font-size: var(--font-size-header);
            }
        }
        h1 {
            display: block;
            color: var(--colour-text-secondary);
            border: none;
            padding: 0 !important;
            margin: 0 var(--spacing-small) 0 0 !important;
            flex-grow: 1;
        }
    }
}