.Picker {

    //> input { display: none; }

    * {
        color: var(--colour-text-primary) !important;
    }

    dl {
        padding: 0;
        margin: 0;
    }

    dt, dd {
        label {
            > span {
                padding-left: 6px;
                white-space: normal;
                min-height: min-content;
                line-height: 125%;
            }
        }
    }

    dd {
        transition: all 0.35s ease-in-out;
        label {
            margin-bottom: 0 !important;
            white-space: normal;
        }
        display: block;
        max-height: 0;
        overflow: hidden;
        transition: all 0.35s ease-in-out;
        &[data-open='true'] {
            max-height: 1600px;
            transition: all 0.35s ease-in-out;
        }
        span { line-height: 125%; }
    }

    > dl > dt {
        font-weight: bold;
    }

    label {
        padding: 6px 0px !important;
        > span:empty { display: none; }
        > span { max-height: 100% !important; }
    }

}