.Featured {

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: var(--colour-background-primary);
    filter: var(--filter-shadow-mid);
    padding: var(--spacing-small);
    padding-bottom: var(--spacing-medium);
    overflow: hidden;

    [data-site='magazines'] & {
        filter: none;
        background: none;
        padding: 0;
        h1 {
            color: var(--colour-text-primary);
        }
    }

    h1 {
        margin-top: 1em;
        margin-bottom: 0.25em;
        padding-bottom: 0;
        font-size: var(--font-size-large);
        text-align: center;
    }

    span {
        margin-top: 0;
        text-align: center;
    }

}

.Background {
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    height: calc( var(--spacing-small) + 100px );
    border-bottom: 1px solid hsl(0 0% 50%);
    [data-site='magazines'] & {
        display: none;
    }
}

.Image {
    border: 1px solid hsl(0 0% 50%);
    border-radius: 100%;
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-bottom: var(--spacing-small);
    filter: grayscale((100%));
    transition: transform 0.2s ease-in-out;
    margin-left: var(--spacing-small);
    margin-right: var(--spacing-small);
    &:hover {
        transform: scale(1.1);
    }
    > img { border-radius: 100%; }
    [data-site='magazines'] & {
        filter: none;
        &:hover {
            transform: none;
        }
    }
}

.BiographyLink {
    display: block;
    position: absolute;
    bottom: 4px;
    width: 100%;
    text-align: center;
    color: var(--colour-text-accent-primary);
}

.Biography {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
    grid-template-areas: "biography" "form";
    grid-template-rows: 1fr min-content;
    padding: 0;
    &[open] { display: grid; }
    > div {
        grid-area: biography;
        max-height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 2em;
        > :first-child {
            padding-top: 0;
            margin-top: 0;
        }
        > :last-child {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
    > a {
        grid-area: form;
        padding: 4px 6px;
        border-top: 1px solid hsl(0 0% 90%);
        color: var(--colour-text-accent-primary);
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}