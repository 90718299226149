.TopicList {

    padding-bottom: var(--spacing-medium);
    padding-top: var(--spacing-medium);

    > ul {
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
        gap: var(--spacing-small);
        justify-content: stretch;
        align-items: stretch;
        margin: 0;
        padding: 0;
        filter: var(--filter-shadow-mid);
        > li {
            list-style-type: none;
            &:first-child:last-child { max-width: var(--card-max-width); }
        }
    }

}