.Menu {
    z-index: 500;
    transition-delay: 0.2s;
    transition: all 0.2s ease-in-out;
    &[data-open='true'] {
        z-index: 1001;
        filter: drop-shadow(0 0 4px hsl(0 0% 30%));
        @media (max-width: 650px) {
            margin-bottom: 8px;
        }
    }
}

.Button {
    z-index: 998;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    border-top-left-radius: var(--spacing-small-half);
    border-top-right-radius: var(--spacing-small-half);
    background: var(--colour-background-primary) !important;
    [data-open='true'] & {
        z-index: 999 !important;
    }
}

.Chevron {
    transform: rotate(-90deg);
    transition: transform 0.2s ease-in-out;
    [data-open='true'] & { transform: rotate(0deg); }
}

.Dropdown {
    [data-site='events'] & {
        position: relative !important;
        @media (max-width: 650px) {
            margin-left: calc( -1 * var(--spacing-small-half) ) !important;
        }
        @media ( min-width: 650px ) {
            position: absolute !important;
        }
    }
    z-index: 999 !important;
    width: min-content !important;
    min-width: calc(100% + var(--spacing-small)) !important;
    height: min-content !important;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    border-bottom-right-radius: var(--spacing-small-half);
    border-bottom-left-radius: var(--spacing-small-half);
    border-top-right-radius: var(--spacing-small-half);
    background: var(--colour-background-primary) !important;
    [data-open='true'] & {
        max-height: 300px;
    }
    > section {
        position: relative;
        > ul {
            display: flex !important;
            flex-direction: column;
            justify-content: flex-start !important;
            align-items: flex-start !important;
            text-align: left;
            padding: 0 var(--spacing-small-half);
            > li > a {
                font-size: var(--font-size-header) !important;
            }
        }
    }
}
