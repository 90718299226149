.Article {

    display: grid;

    @media ( min-width: 0px ) {
        grid-template-areas:
            "title"
            "story"
            "related";
        grid-template-columns: 1fr;
    }
    @media ( min-width: 1100px ) {
        grid-template-areas:
            "title title"
            "story related";
        grid-template-columns: 2fr 1fr;
    }

    background: var(--colour-background-primary);
    > div {
        display: block;
        @media ( min-width: 0px ) {
            padding: var(--spacing-medium) var(--page-padding-horizontal);
        }
        @media ( min-width: 1100px ) {
            padding:
                var(--spacing-medium)
                var(--spacing-large)
                var(--spacing-medium)
                var(--page-padding-horizontal);
        }
        > * {
            display: contents;
            > * {
                grid-column: 1 / -1;
            }
            p:first-child { margin-top: 0; }
        }
    }
}

.Title {
    grid-area: title;
    background: var(--colour-background-accent-primary);
    color: var(--colour-text-secondary);
    [data-site='events'] & {
        background: var(--colour-background-accent-tertiary);
        color: white;
    }
    padding: var(--spacing-medium) var(--page-padding-horizontal);
    h1 {
        font-size: var(--font-size-hero);
        margin-top: 0;
        margin-bottom: 0;
    }
    h1, p {
        color: var(--colour-text-secondary);
        [data-site='events'] & {
            color: white;
        }
    }
    > :last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.Story {
    grid-area: story;
    background: var(--colour-background-primary);
    padding-right: var(--spacing-medium) !important;
    @media ( min-width: 1100px ) {
        padding-right: var(--spacing-medium) !important;
    }
}

.Related {

    grid-area: related;

    max-width: var(-card-min-width);
    background: var(--colour-background-tertiary);

    @media ( min-width: 0px ) {
        padding: var(--spacing-medium) var(--page-padding-horizontal) !important;
        border-top: 1px solid hsl(0 0% 80%);
    }
    @media ( min-width: 1100px ) {
        padding: var(--spacing-medium) !important;
        border-left: 1px solid hsl(0 0% 80%);
    }

    header + header {
        display: none;
    }

    picture { display: none; }
    li {
        border: 0 !important;
    }

    ul { max-width: var(--card-min-width); }

}
