.LoginButton > span {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: var(--spacing-small);

    strong {
        font-weight: normal;
        color: var(--colour-text-accent-primary);
        white-space: nowrap;
    }

}