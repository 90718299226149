.Field {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    padding: 4px 0 !important;
    > .Type {
        order: 2 !important;
        width: auto;
        flex-grow: 0;
        flex-shrink: 0;
        overflow: visible;
        color: var(--colour-text-tertiary);
    }
    > .Label {
        order: 3 !important;
        opacity: 1 !important;
        max-height: 1000px !important;
        line-height: 1.6em !important;
    }
    > input {
        order: 1 !important;
        margin: 0 !important;
    }
}