.Browser {

    background: var(--colour-background-primary) !important;

    > ul {
        padding-left: 0;
        display: grid;
        grid-template-columns: 100%;
        gap: var(--spacing-medium);
        > li {
            list-style-type: none;
        }
    }

}

.Filters {

    display: grid !important;
    justify-content: center;

    @media ( min-width: 0px ) {
        grid-template-areas:
            "type"
            "portfolio"
            "topic"
            "reset";
        grid-template-columns: 1fr;
        > button { justify-self: center; }
    }
    @media ( min-width: 650px ) {
        grid-template-areas:
            "type portfolio"
            "reset topic";
        grid-template-columns: min-content min-content;
        > button { justify-self: flex-start; }
    }
    @media ( min-width: 1290px ) {
        grid-template-areas: "type portfolio topic reset";
        grid-template-columns: min-content min-content min-content min-content;
    }

    > span {
        min-width: 100%;
        select { min-width: 100%; }
    }

}

.TypeFilter { grid-area: type; }
.PortfolioFilter { grid-area: portfolio; }
.TopicFilter { grid-area: topic; }
