.PortfolioList {

    display: flex;
    flex-direction: column;
    padding: var(--spacing-medium) 0 var(--spacing-small) 0 !important;

    > nav { display: none; }

    > header {
        display: flex;
        flex-direction: row;
        gap: 0 var(--spacing-large);
        @media (max-width: 900px) {
            flex-direction: column;
        }
        justify-content: flex-start;
        margin-left: calc( var(--page-padding-horizontal) + var(--spacing-medium) );
        margin-bottom: calc( -1 * var(--spacing-large) - var(--spacing-small) );
        
        @media( min-width: 0px ) {
            padding:
                var(--spacing-small)
                var(--page-padding-horizontal)
                var(--spacing-large)
                var(--page-padding-horizontal);
        }
        @media( min-width: 900px ) {
            padding:
                var(--spacing-small)
                var(--page-padding-horizontal)
                var(--spacing-large)
                var(--spacing-large);
        }
        background: var(--colour-background-accent-primary);
        > h1 {
            color: var(--colour-text-secondary);
            @media( min-width: 350px ) { white-space: nowrap; }
        }
        > p {
            padding-bottom: var(--spacing-small-half);
            color: var(--colour-text-tertiary);
        }
        > h1 + * { margin-top: 0; }
        a { color: var(--colour-text-secondary); }
        &:empty { display: none; }
    }

    > ul > li { list-style-type: none; }

    &[data-type='Slider'] {
        max-width: 100vw;
        overflow-x: hidden;
        > ul {
            padding: 0;
            gap: var(--spacing-small);
            > li {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: var(--spacing-small) 0 0 0;
                padding: 0;
                @media( min-width: 350px ) {
                    &:first-child { padding-left: var(--page-padding-horizontal); }
                    &:last-child { padding-right: var(--page-padding-horizontal); }
                }
                text-align: center;
                picture { max-width: 100vw; }
            }
        }
        > ul + nav {
            display: flex;
        }
    }

    &[data-type='Expanded'] {
        > ul {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding: 0;
            margin: 0;
        }
        > ul + nav {
            display: none;
        }
    }

}

.Navigation {

    [data-type='Slider'] & {
        display: none;
    }

    [data-type='Expanded'] & {
        display: block;
        margin: 0 var(--page-padding-horizontal) var(--spacing-medium) var(--page-padding-horizontal);
        @media( min-width: 0px ) {
            padding: var(--spacing-medium) var(--spacing-small) 0 0;
        }
        @media( min-width: 350px ) {
            padding: var(--spacing-medium) var(--spacing-small) 0 var(--spacing-small);
        }
        border-radius: var(--border-radius-small);
        background: var(--colour-background-primary);
        filter: var(--filter-shadow-mid);
        > ul {
            display: grid;
            @media( min-width: 0px ) {
                grid-template-columns: repeat(auto-fill, minmax(var(--card-min-width),1fr) );
            }
            @media( min-width: 800px ) {
                grid-template-columns: repeat(auto-fill, minmax(350px,1fr) );
            }
            padding: 0;
            margin: 0;
            > li {
                list-style-type: none;
                padding: 0 var(--spacing-small-half) var(--spacing-medium) var(--spacing-small-half);
                > a {
                    display: flex;
                    flex-direction: row;
                    height: 100%;
                    font-size: var(--font-size-large);
                    svg path {
                        fill: var(--colour-text-accent-secondary) !important;
                    }
                    > span {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        &:last-child::after {
                            display: block;
                            content: '';
                            width: var(--spacing-medium);
                            margin-top: var(--spacing-small-half);
                            border-bottom: 1px solid var(--colour-text-accent-primary);
                        }
                    }
                }
            }
        }
    }

}

.Logo {
    width: 46px !important;
    min-width: 46px !important;
    height: 46px !important;
    max-width: 46px !important;
    margin-right: var(--spacing-small);
    svg path {
        fill: var(--colour-text-accent-primary) !important;
    }
}

[data-type='Hero_Image'] + .PortfolioList[data-type='Expanded'] {
    > nav {
        margin-top: calc( -1.5 * var(--spacing-large) );
        z-index: var(--layer-body-high);
    }
    padding-bottom: var(--spacing-large) !important;
}
