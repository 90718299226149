@use 'styles/magazine-heading' as *;

.PersonList {

    padding: 0 !important;

    > header:empty { display: none; }

    > ul {
        padding: var(--spacing-small) 0;
        margin: 0;
        filter: var(--filter-shadow-mid);
        gap: var(--spacing-small);
        > li {
            > * {
                height: 100%;
                filter: none;
            }
            white-space: normal;
            padding: 0;
            @media( min-width: 350px ) {
                &:first-child {
                    padding-left: var(--page-padding-horizontal);
                }
                &:last-child {
                    padding-right: var(--page-padding-horizontal);
                }
            }
        }
        [data-site='magazines'] & {
            display: grid;
            grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
            filter: none;
            padding: var(--spacing-small) var(--page-padding-horizontal) var(--spacing-medium) var(--page-padding-horizontal);
            li {
                padding: 0;
            }
        }
    }
}

.Header {
    padding: 0 var(--page-padding-horizontal);
    margin-top: var(--spacing-medium);
    margin-bottom: var(--spacing-small);
    > h1:last-child { margin-bottom: 0; }
    p { color: var(--colour-text-primary); }
    [data-site='magazines'] & {
        > h1 {
            @include magazine-heading;
        }
    } 
}