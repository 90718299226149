.Link {
    border: 0 !important;
    padding: 0 !important;
    background: none !important;
}

.Image {
    width: 40px;
    height: 40px;
    svg {
        fill: var(--colour-text-accent-primary);
    }
}
