.Featured {

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    width: 225px;
    max-width: 100%;
    height: auto;

    [data-site='events'] & {
        width: auto;
        max-width: 200px;
    }

    @media (max-width: 600px) {
        width: 150px;
    }

    > span {
        margin-top: 0;
        text-align: center;
    }

}

.Image {

    margin-right: var(--spacing-small);

    > img { transform: none !important; }

}
