.Featured {

    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
    > a {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: relative;
        font-size: var(--font-size-header);
        color: var(--colour-text-accent-primary);
    }
    p { padding-bottom: 0; }
    hr {
        width: 100%;
        border-width: 1px 0 0 0;
        border-style: solid;
        border-color: var(--colour-text-accent-primary);
        margin-top: var(--spacing-small-three-quarters);
        margin-bottom: var(--spacing-small-three-quarters);
    }

    [data-type='Icons'] & {
        justify-content: center;
        align-items: center;
        a {
            align-items: center;
            text-align: center;
            font-size: var(--font-size-medium);
            filter: var(--filter-shadow-low);
            hr {
                border: 0;
                margin: 6px 0;
            }
            > div {
                margin-bottom: var(--spacing-small-half);
            };
        }
        a ~ * { display: none; }
    }

}

.Image {

    [data-type='Icons'] & {
        --icon-size: 64px;
        --icon-padding: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        height: calc( var(--icon-size) + 2 * var(--icon-padding) ) !important;
        width: calc( var(--icon-size) + 2 * var(--icon-padding) ) !important;
        max-width: calc( var(--icon-size) + 2 * var(--icon-padding) ) !important;
        padding: var(--icon-padding);
        background: var(--colour-text-accent-primary);
        border-radius: 100%;
        overflow: hidden;
        * {
            stroke: var(--colour-text-secondary);
            fill: var(--colour-text-secondary);
        }
    }

}