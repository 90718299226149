.RelatedContentList {

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: var(--spacing-medium) !important;

    > header {
        align-self: flex-start;
        margin-left: -1px;
    }

    > ul {
        margin: 0;
        padding: 0;
        display: grid;
            width: 100%;
            grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
            gap: var(--spacing-small);
            padding-bottom: var(--spacing-small);
        > li {
            list-style-type: none;
            border-top: 1px solid var(--colour-text--primary);
            border-bottom: 1px solid var(--colour-text-accent-primary);
            padding-top: 0;
            padding-bottom: var(--spacing-small);
            margin-bottom: calc( -1 * var(--spacing-small) - 1px );
            &:first-child:last-child { max-width: var(--card-max-width); }
        }
    }

    &[data-articles='0'] option[value='article'],
    &[data-databases='0'] option[value='database'],
    &[data-events='0'] option[value='event'],
    &[data-insight-reports='0'] option[value='insight-report'],
    &[data-magazines='0'] option[value='magazine'],
    &[data-market-reports='0'] option[value='market-report'],
    &[data-newsletters='0'] option[value='newsletter'],
    &[data-polymer-demands='0'] option[value='polymer-demand'] {
        display: none;
    }

}