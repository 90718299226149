.SponsorList {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: var(--spacing-medium);
    padding-bottom: var(--spacing-medium);

    > ul {

        width: 100%;
        display: grid;
        list-style-type: none;
        align-items: center;
        justify-content: center;

        grid-template-columns: repeat(auto-fit,minmax(200px,1fr));

        @media (max-width: 600px) {
            grid-template-columns: repeat(auto-fit,minmax(100px,1fr));
        }

        gap: var(--spacing-small);
        padding: 0;

        > li {
            list-style-type: none;
            min-height: 0;
            height: min-content;
        }
    }

}
