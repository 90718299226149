.Error {
    padding: 50px 0px;
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    margin: auto;
    gap: 10px;
    h1 {
        color: var(--colour-text-accent-primary);
    }
    h3 {
        color: var(--colour-text-primary);
    }
    a {
        color: var(--colour-link-active);
        &:hover {
            color: var(--colour-link);
        }
    }
}