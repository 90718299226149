@use 'styles/energy-heading' as *;
@use 'styles/magazine-heading' as *;

.ComponentBodyProse {

    background: var(--colour-background-primary);

    li {
        list-style-type: square;
        &::marker {
            color: var(--colour-text-accent-primary);
        }
    }

    img {
        margin-top: var(--spacing-small);
        margin-bottom: var(--spacing-small);
        max-width: 100%;
    }

    a {
        color: var(--colour-text-accent-primary);
        text-decoration: none;
        transition: all var(--transition-fast) ease-in-out;
        &:hover {
            color: var(--colour-text-accent-primary);
            text-decoration: underline;
        }
        [data-site='events'] & {
            color: var(--colour-background-accent-secondary);
            &:hover {
                color: var(--colour-background-accent-tertiary);
            }
        }
    }

    [data-site='materials-energy-expo'] article > div > & {
        h1 {
            @include energy-heading;
        }
    }
    [data-site='materials-energy-expo'] & ,
    [data-site='magazines'] & {
        p:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
    [data-site='magazines'] article > div > & {
        h1 {
            @include magazine-heading;
        }
    }

    &[data-align='left'] {
        text-align: start !important;
    }
    &[data-align='right'] {
        text-align: end !important;
    }
    &[data-align='center'] {
        text-align: center !important;
    }
}
