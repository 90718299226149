.Featured {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: var(--font-size-medium);
        > picture, > img, svg {
            filter: opacity(0.7);
            margin-bottom: var(--spacing-small-half);
            transition: var(--transition-fast) filter ease-in-out;
        }
        &:hover img,
        &:hover picture,
        &:hover svg { filter: opacity(0.5); }
    }
    hr {
        width: 4ch;
        border-width: 1px 0 0 0;
        border-style: solid;
        border-color: var(--colour-text-accent-primary);
        margin-top: var(--spacing-small-half);
        margin-bottom: var(--spacing-small-half);
    }
    p { margin: 0; padding: 0; }
}

.Image {
    width: 60px;
    height: 60px;
}
