.ActivityList {

    display: flex;
    flex-direction: column;
    align-items: center;

    > header {
        padding: var(--spacing-small) 0 var(--spacing-small-half) 0;
        text-align: center;
        color: var(--colour-text-accent-secondary);
        > * {
            max-width: 90ch;
            padding-left: var(--spacing-medium);
            padding-right: var(--spacing-medium);
            text-align: center;
        }
        > h1 {
            margin-bottom: 0;
        }
        > p {
            font-size: 1.5em;
            line-height: 1.2em;
            padding: 0;
        }
        > :first-child {
            margin-top: 0;
            padding-top: 0;
        }
        > :last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    > ul {

        width: 100%;
        display: grid;

        grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));

        &[data-number='4'] {
            @media (min-width: 0px) {
                grid-template-columns: 100%;
            }
            @media (min-width: 700px) {
                grid-template-columns: 50% 50%;
            }
            @media (min-width: 1366px) {
                grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
            }
        }

        gap: var(--spacing-small);
        padding: 0;
        filter: var(--filter-shadow-mid);

        > li {
            list-style-type: none;
            border-radius: var(--border-radius-small);
            background: var(--colour-background-primary);
            padding: var(--spacing-small);
        }
    }

}

[data-type='Hero_Image'] + .ActivityList {
    > ul {
        margin-top: calc( -1 * var(--spacing-large) );
        z-index: var(--layer-body-high);
    }
}
