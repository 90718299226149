.Hits {

    display: flex;
    [data-site='events'] & { display: none; }
    flex-direction: column;
    max-height: 10000px;
    max-width: 100vw;
    overflow-y: auto;
    //transition: all 0.5s ease-in-out;
    padding-top: var(--spacing-medium);
    padding-bottom: var(--spacing-large);
    padding-left: var(--page-padding-horizontal);
    padding-right: var(--page-padding-horizontal);
    @media(min-width: 525px) {
        margin-left: calc(-1 * var(--page-padding-horizontal));
        margin-right: calc(-1 * var(--page-padding-horizontal));
    }
    background: var(--colour-background-primary);
    font-size: var(--font-size-medium) !important;
    background: var(--colour-background-secondary);

    &:global(.ais-InfiniteHits--empty) {
        [data-display-state='Search'] & {
            overflow: visible;
        }
        &::after {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            content: 'No results found';
            color: var(--colour-text-primary);
        }
    }

    div > a {
        text-transform: none !important;
        font-variant: normal !important;
    }

    > button {
        border: none;
        background: none;
        cursor: pointer;
        color: var(--colour-text-accent-primary);
        &:hover { color: var(--colour-text-accent-secondary); }
        &[disabled] { display: none; }
    }

    ol {
        margin: 0;
        padding: 0 0 var(--spacing-medium) 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(var(--card-min-width),1fr));
        gap: var(--spacing-small);
        filter: var(--filter-shadow-low);
        p:not(:has(span)) { display: none }
        > li {
            background: var(--colour-background-primary);
            padding: var(--spacing-small-half) var(--spacing-small) var(--spacing-small) var(--spacing-small);
            border-radius: var(--spacing-small);
            a ~ a { display: none; }
            a:hover { color: var(--colour-text-accent-secondary) !important; }
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

}
