.Filters {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--spacing-small-half) var(--spacing-medium);

    height: min-content;
    max-width: 100vw;

    margin-top: calc( -1 * var(--spacing-medium) - var(--spacing-small-half) );
    z-index: var(--layer-body-high);

    padding:
        var(--spacing-small-half)
        var(--spacing-small-half)
        var(--spacing-small-quarter)
        var(--spacing-small-half);
    @media ( max-width: 1050px ) { padding-top: var(--spacing-small); }
    border-radius: var(--border-radius-small);
    background: hsla(0 0% 100% / 0.95);
    backdrop-filter: grayscale(1) blur(4px);
    filter: var(--filter-shadow-mid);
    margin-bottom: var(--spacing-medium);
    margin-left: calc( -1 * var(--spacing-small) );
    margin-right: calc( -1 * var(--spacing-small) );

    * { font-size: var(--font-size-small); }

    > span {
        display: flex;
        flex-direction: column;
        &[data-expanded='true'] {
            flex-direction: row;
            gap: var(--spacing-small);
            padding: var(--spacing-small-quarter);
            align-items: center;
            [data-site='events'] & {
                @media (max-width: 600px) {
                    flex-direction: column;
                    gap: var(--spacing-small-half);
                }
            }
        }
        align-items: flex-start;
        margin: 0;
        justify-self: stretch;
        label {
            font-size: var(--font-size-tiny);
            font-variant: small-caps;
            text-transform: lowercase;
            color: var(--colour-text-accent-secondary);
            margin-bottom: 4px;
        }
        > button {
            text-wrap: nowrap;
            background: none !important;
            border: none;
            border-bottom: 4px solid transparent;
            &[data-active='true'] { border-bottom: 4px solid var(--colour-background-accent-secondary); }
            padding: 0 !important;
            cursor: pointer;
            font-size: var(--font-size-normal);
        }
    }

    select {
        padding: 8px 0 0 0;
        text-align: left;
        border: none;
        border-top: 1px solid var(--colour-text-accent-primary);
        outline: none;
        background: transparent;
        appearance: none;
        &:hover { color: var(--colour-text-accent-primary); }
    }

    > button {
        grid-area: reset;
        align-self: center;
        width: min-content;
    }

}
