.Summary {

    display: flex;
    height: 100%;
    max-width: 800px;
    flex-direction: column;
    background: var(--colour-background-accent-primary);
    overflow: hidden;

    --colour-link: var(--colour-text-tertiary);

    > a { display: contents; }
    > a span {
       white-space: nowrap;
       width: min-content;
       align-self: center;
       justify-self: flex-end;
       margin: var(--spacing-small);
    }

    &:hover {
        span {
            border: 1px solid var(--colour-link-active);
            color: var(--colour-link-active);
        }
    }

}

.Image {
    height: 250px;
    overflow: hidden;
}

.Text {

    flex-grow: 1;

    color: var(--colour-text-tertiary);
    padding: var(--spacing-small);
    padding-bottom: 0;
    background: var(--colour-background-accent-primary);
    
    h1 {
        margin-top: 0;
        color: var(--colour-text-secondary);
        font-size: var(--font-size-large);
    }
    
    > :last-child,
    > p:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
    }

}

.Links {
    padding: var(--spacing-small);
    display: flex;
    flex-direction: row;
    justify-content: center;
}