@use 'styles/energy-heading' as *;
@use 'styles/magazine-heading' as *;

.Form {

    display: flex;
    flex-direction: column;
    align-items: stretch;

    padding: var(--spacing-small) var(--spacing-small) 0 var(--spacing-small);

    background: var(--colour-background-tertiary);

    > section { background: transparent !important; }

    h1 { color: var(--colour-text-accent-primary) !important; }
    [data-site='magazines'] & {
        background: transparent;
        h1 { 
            @include magazine-heading;
            color: var(--colour-text-primary) !important; 
        }

    }

    legend {
        width: max-content;
        padding: 0 0.5em;
        white-space: normal;
        max-width: 65vw;
        font-size: var(--font-size-small);
        color: var(--colour-text-accent-secondary);
        text-align: center;
    }

    fieldset {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border: 1px solid var(--colour-text-tertiary);
        [data-site='magazines'] & {
            border: 1px solid var(--colour-background-accent-primary);
        }
        border-radius: var(--spacing-small-half);
        padding-top: var(--spacing-small-half);
        padding-bottom: var(--spacing-small-half);
        margin-bottom: var(--spacing-small);
    }

    header {
        display: none;
        &:empty { display: none; }
    }

    label {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        > span {
            order: 1;
            padding-left: 20px;
            font-size: var(--font-size-small);
            color: var(--colour-text-accent-secondary);
            transition: all 0.35s ease-in-out;
            line-height: 100%;
        }
        > input,
        > textarea,
        > select {
            padding: 0.5em;
            order: 2;
            margin: 0 0.5em 0.5em 0.5em;
            border: 1px solid var(--colour-text-tertiary);
            [data-site='magazines'] & {
                border: 1px solid var(--colour-background-accent-primary);
            }
            border-radius: 6px;
            &:not([type='checkbox']) + span {
                max-height: 0;
                opacity: 0;
                overflow: hidden;
            }
            &:not([type='checkbox']):not(:placeholder-shown) + span {
                max-height: 100px;
                opacity: 1;
                transition: all 0.35s ease-in-out;
            }
            > span {
                [data-site='materials-energy-expo'] & {
                    font-size: var(--font-size-small);
                }
            }
        }
        > input:not([type='checkbox']),
        > select {
            width: calc(100% - 2 * var(--spacing-small-half));
        }
        > textarea {
            border-radius: var(--spacing-small-half);
            border-bottom-right-radius: 0;
            padding: 0.5em 1em;
            resize: none;
        }
        > select {
            margin-top: 8px;
            font-size: var(--font-size-tiny);
            appearance: none;
            background: transparent !important;
            [data-site='materials-energy-expo'] & {
                color: var(--colour-text-primary);
                font-size: var(--font-size-small);
            }
            height: 45px;
        }
        input[type='checkbox'] {
            align-self: center;
            margin-right: 0.25em !important;
        }
        &:not(:last-child) { margin-bottom: 0.5em; }
    }

    ::placeholder {
        font-size: var(--font-size-small);
        color: var(--colour-text-accent-secondary);
        [data-site='magazines'] & {
            color: var(--colour-text-primary);
        }
    }

    > label {
        > * {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
        > span {
            padding-left: 10px;
        }
    }

    input[type='submit'] {
        padding: 8px;
        height: min-content;
        [data-site='magazines'] & {
            width: fit-content;
            max-width: 100%;
            padding: 10px 80px;
            background: var(--colour-background-accent-primary);
            border: none;
            border-radius: var(--spacing-small-half);
            color: var(--colour-text-secondary);
            display: flex;
            justify-content: center;
        }
        [data-site='events'] & {
            margin-bottom: var(--spacing-small-half);
        }
    }

    label + span,
    dl + span {
        color: red !important;
        font-size: var(--font-size-tiny);
        margin-top: calc( -1 * var(--spacing-small-half) );
        margin-bottom: var(--spacing-small-three-quarters);
        padding-left: 18px;
    }

    dl + span {
        margin-top: 0;
        margin-bottom: 0;
    }

    [data-site='events'] & {
        a {
            display: inline !important;
        }
    }
}

.Submitted {
    height: 100%;
    background: var(--colour-background-tertiary);
    padding: var(--spacing-medium);
    [data-site='materials-energy-expo'] & {
        background: var(--colour-background-secondary);
    }
}

.Error {
    color: red;
    padding-bottom: 0;
    margin-bottom: 0;
    &:empty { display: none; }
}

.Recaptcha {
    margin-top: var(--spacing-small-half);
    font-size: var(--font-size-tiny) !important;
    font-weight: normal !important;
    a {
        white-space: nowrap;
        color: var(--colour-text-accent-secondary);
        &:hover { color: var(--colour-text-accent-primary); }
        [data-site='events'] & {
            color: var(--colour-background-accent-secondary) !important;
            background: none !important;
            padding: 0 !important;
            margin-right: 0 !important;
            font-size: inherit !important;
            :hover {
                color: var(--colour-link-active) !important;
            }
        }
    }
}
