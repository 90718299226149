@use 'styles/events-button' as *;
.BodyContent {

    display: flex;
    flex-direction: column;
    max-width: 100vw;
    scroll-padding-top: 150px;

    > * {
        padding: var(--spacing-small) var(--page-padding-horizontal);
        --background: var(--colour-background-primary);
        background: var(--background);
        margin: 0;
    }

    > ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        [data-site='events'] & {
            margin-top: 0;
            padding-top: 0;
        }
        > li {
            list-style-type: none;
            a {
                [data-site='main'] & ,
                [data-site='magazines'] & {
                    --colour-link: var(--colour-text-primary);
                    --colour-link-active: var(--colour-text-accent-primary);
                }
                [data-site='events'] & {
                    @include events-button;
                    color: var(--colour-link);
                    margin-top: 0;
                }
            }
        }
    }


}
